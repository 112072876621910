<template>
  <div class="remote-control-wrapper">
    remote control...
  </div>
</template>

<script>
export default {
  name: 'RemoteControl',
  data() {
    return {};
  },
};
</script>

<style></style>
